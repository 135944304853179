<app-action-popup
  [(visible)]="visible"
  (onHidden)="visibleChange.emit(visible)"
  (onShowing)="resetOptionForm()"
  [title]="title"
  (onSave)="save()"
  maxWidth="910px"
>
  <app-option-copy-form
    [(optionToCopyFormData)]="optionCopyFormData"
    [preislistenKataloge]="preislistenKataloge"
    [optionGroups]="optionGroups"
  >
  </app-option-copy-form>
</app-action-popup>
