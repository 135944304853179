<app-action-popup
  [(visible)]="visible"
  [title]="title"
  [showLeftButton]="false"
  [rightButtonText]="buttonText"
  [closeOnOutsideClick]="false"
  (onSave)="visibleChange.emit(false)"
  maxWidth="700px"
>
  <app-alert [multiLine]="true">
    <span style="white-space: pre-line"> {{ textMessage }}</span>
  </app-alert>
</app-action-popup>
