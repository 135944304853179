import { PreislistenKatalog } from "./produkt";
import { Option } from "./option";

class BaseOptionGroup {
  id: string;
  groupName: string;
  sortId: number;

  // US
  ancillaryType: string;
}

export class OptionGroup extends BaseOptionGroup {
  preislistenKatalog: PreislistenKatalog;
}

// Same as OptionGroup in Backend
export class SimpleOptionGroup extends BaseOptionGroup {
  preislistenKatalogId: string;
}
