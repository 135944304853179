<dx-popup
  [(visible)]="visible"
  [showTitle]="true"
  [hideOnOutsideClick]="closeOnOutsideClick"
  [showCloseButton]="false"
  [maxWidth]="maxWidth"
  [dragEnabled]="false"
  height="auto"
  maxHeight="90%"
  (onHidden)="visibleChange.emit(visible); onHidden.emit()"
  (onShowing)="onShowing.emit()"
>
  <dxi-toolbar-item location="before">
    <div *dxTemplate="let data of 'title'">
      <p class="popup-title">{{ title }}</p>
    </div>
  </dxi-toolbar-item>

  <div *dxTemplate="let data of 'content'" style="padding-top: 18px">
    <dx-scroll-view>
      <ng-content></ng-content>

      <div
        class="d-flex"
        [class]="isXSmall ? 'flex-column' : 'align-items-center'"
        style="margin-top: 18px; justify-content: end"
      >
        <div
          class="d-flex flex-shrink"
          style="align-items: center; color: darkgrey"
          [style]="isXSmall ? 'margin-bottom: 10px' : ''"
        >
          <ng-content select="[footer]"></ng-content>
        </div>

        <div
          class="d-flex flex-grow"
          style="align-items: center; justify-content: end"
        >
          <dx-button
            [visible]="showLeftButton"
            [text]="leftButtonText"
            stylingMode="text"
            (onClick)="visible = false"
            style="margin: 0 10px"
          ></dx-button>

          <dx-button
            [visible]="showRightButton"
            [text]="rightButtonText"
            type="default"
            (onClick)="onSave.emit()"
          ></dx-button>
        </div>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>
