<div class="container">
  <div class="images">
    <ng-container *ngIf="isUsa; else isNotUsa">
      <a href="https://www.bpg-usa.com/" target="_blank">
        <img class="brand-logo" src="assets/bpgusa.jpg" alt="BPG USA" />
      </a>
    </ng-container>
    <ng-template #isNotUsa>
      <a href="{{ formatMessage('footer.ggplink') }}" target="_blank">
        <img class="brand-logo" src="assets/ggp.jpg" alt="zu GGP" />
      </a>
    </ng-template>
  </div>
  <div class="copyright">
    <ng-container *ngIf="isUsa; else isNotUsaDiv">
      <div>© BPG USA a part of Bertelsmann Marketing Services</div>
    </ng-container>
    <ng-template #isNotUsaDiv>
      <div>© GGP Media a part of Bertelsmann Marketing Services</div>
    </ng-template>
    <div>
      <dx-button
        stylingMode="text"
        text="{{ formatMessage('footer.imprint') }}"
        type="normal"
        (onClick)="navigateAndResetTreeView('/impressum')"
      >
      </dx-button>
      <dx-button
        stylingMode="text"
        text="{{ formatMessage('footer.dataprotection') }}"
        type="normal"
        (onClick)="navigateAndResetTreeView('/datenschutz')"
      >
      </dx-button>
      <dx-button
        stylingMode="text"
        text="{{ formatMessage('footer.tcs') }}"
        type="normal"
        (onClick)="navigateAndResetTreeView('/agb')"
      >
      </dx-button>
      <dx-button
        stylingMode="text"
        text="{{ formatMessage('footer.tou') }}"
        type="normal"
        (onClick)="navigateAndResetTreeView('/nutzungsbedingungen')"
      >
      </dx-button>
    </div>
  </div>
</div>
