import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  OptionCopyFormComponent,
  OptionCopyFormModule,
} from "../option-copy-form/option-copy-form.component";
import { ActionPopupModule } from "../../action-popup/action-popup.component";
import { DxFormComponent, DxToastModule } from "devextreme-angular";
import {
  OptionFormComponent,
  OptionFormModule,
} from "../option-form/option-form.component";
import { Option } from "../../../model/calculation/option";
import { PreislistenKatalog } from "../../../model/calculation/produkt";
import { OptionCopyFormData } from "../../../model/calculation/OptionCopyFormData";
import { formatMessage } from "devextreme/localization";
import { OptionGroup } from "../../../model/calculation/optionGroup";

@Component({
  selector: "app-option-copy-form-popup",
  templateUrl: "./option-copy-form-popup.component.html",
  styleUrls: ["./option-copy-form-popup.component.scss"],
})
export class OptionCopyFormPopupComponent implements OnInit {
  formatMessage = formatMessage;
  @ViewChild(OptionCopyFormComponent) optionCopyForm: OptionCopyFormComponent;
  @Input() visible: boolean = false;
  @Input() optionCopyFormData: OptionCopyFormData;
  @Input() preislistenKataloge: PreislistenKatalog[];
  @Input() optionGroups!: OptionGroup[];

  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() onSave = new EventEmitter<OptionCopyFormData>();

  showToast: boolean = false;
  title: string = formatMessage("option.copy");
  constructor() {}

  ngOnInit(): void {}

  resetOptionForm() {
    this.optionCopyForm.resetForm();
  }

  validateForm() {
    return this.optionCopyForm.isValid();
  }

  save() {
    if (!this.validateForm()) {
      return;
    }
    const data = { ...this.optionCopyFormData };
    this.onSave.emit(data);
  }
}

@NgModule({
  imports: [
    OptionCopyFormModule,
    ActionPopupModule,
    DxToastModule,
    OptionFormModule,
  ],
  declarations: [OptionCopyFormPopupComponent],
  exports: [OptionCopyFormPopupComponent],
})
export class OptionCopyFormPopUpModule {}
