import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  DxFormComponent,
  DxFormModule,
  DxToastModule,
} from "devextreme-angular";
import { PreislistenKatalog } from "../../../model/calculation/produkt";
import { OptionCopyFormData } from "../../../model/calculation/OptionCopyFormData";
import { formatMessage } from "devextreme/localization";
import { CommonModule } from "@angular/common";
import { OptionService } from "../../../services/option.service";
import { OptionGroup } from "../../../model/calculation/optionGroup";

@Component({
  selector: "app-option-copy-form",
  templateUrl: "./option-copy-form.component.html",
  styleUrls: ["./option-copy-form.component.scss"],
})
export class OptionCopyFormComponent implements OnInit {
  formatMessage = formatMessage;
  @ViewChild(DxFormComponent) form: DxFormComponent;

  @Input() optionToCopyFormData!: OptionCopyFormData;
  @Input() preislistenKataloge!: PreislistenKatalog[];
  @Input() optionGroups!: OptionGroup[];
  @Output() optionToCopyFormDataChange = new EventEmitter<OptionCopyFormData>();

  loadedOptionGroups: OptionGroup[] | null;
  createNewOptionGroup: boolean = false;

  toastType: string;
  toastMessage: string;
  showToast: boolean;

  constructor(private optionService: OptionService) {
    this.preislistenKatalogSelected =
      this.preislistenKatalogSelected.bind(this);
    this.shouldCreateNewOptionGroup =
      this.shouldCreateNewOptionGroup.bind(this);
  }

  ngOnInit(): void {}

  resetForm() {
    this.form.instance.resetValues();
    this.loadedOptionGroups = null;
    this.createNewOptionGroup = false;
  }

  isValid() {
    return this.form.instance.validate().isValid;
  }

  loadOptionGroups(): void {
    console.log("loadOption");
    this.optionService
      .findOptionGroupsByPreislistenKatalog(
        this.optionToCopyFormData.preislistenKatalog
      )
      .toPromise()
      .then((result) => {
        const loadedOptionGroups = [...result];

        if (loadedOptionGroups.length > 1) {
          this.optionService.sortOptionGroups(loadedOptionGroups);
        }

        this.loadedOptionGroups = loadedOptionGroups;
      })
      .catch(() => {
        this.toastType = "error";
        this.toastMessage = formatMessage("optiongroup.load.error");
        this.showToast = true;
      });
  }

  preislistenKatalogSelected(data: any) {
    if (
      data.previousValue == null ||
      data.value == data.previousValue ||
      data.value == null
    ) {
      return;
    }
    // delete selected OptionGroup
    this.form.instance.option("formData").optionGroup = null;

    this.loadOptionGroups();
  }

  shouldCreateNewOptionGroup(data: any) {
    if (data.value) {
      this.form.instance.option("formData").optionGroup = null;
    }
    this.createNewOptionGroup = data.value;
  }
}

@NgModule({
  imports: [DxFormModule, CommonModule, DxToastModule],
  declarations: [OptionCopyFormComponent],
  exports: [OptionCopyFormComponent],
})
export class OptionCopyFormModule {}
