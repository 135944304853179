<dx-form
  id="optionCopyForm"
  labelMode="floating"
  [formData]="optionToCopyFormData"
  colCount="2"
>
  <dxi-item
    #preislistenKatalog
    name="preislistenKatalog"
    dataField="preislistenKatalog"
    [editorOptions]="{
      stylingMode: 'underlined',
      dataSource: preislistenKataloge,
      displayExpr: 'name',
      searchEnabled: true,
      onValueChanged: preislistenKatalogSelected
    }"
    editorType="dxSelectBox"
  >
    <dxo-label text="{{ formatMessage('preislistenKatalog') }}"></dxo-label>
    <dxi-validation-rule
      type="required"
      [message]="formatMessage('validation.required')"
    ></dxi-validation-rule>
  </dxi-item>

  <dxi-item
    *ngIf="!createNewOptionGroup"
    #optionGroup
    name="optionGroup"
    dataField="optionGroup"
    [editorOptions]="{
      stylingMode: 'underlined',
      dataSource: loadedOptionGroups ? loadedOptionGroups : optionGroups,
      displayExpr: 'groupName'
    }"
    editorType="dxSelectBox"
  >
    <dxo-label text="{{ formatMessage('optionGroup') }}"></dxo-label>
    <dxi-validation-rule
      type="required"
      [message]="formatMessage('validation.required')"
    ></dxi-validation-rule>
  </dxi-item>

  <dxi-item
    *ngIf="createNewOptionGroup"
    dataField="optionGroup.groupName"
    [editorOptions]="{ stylingMode: 'underlined' }"
  >
    <dxo-label text="{{ formatMessage('optionGroup.name') }}"></dxo-label>
    <dxi-validation-rule
      type="required"
      [message]="formatMessage('validation.required')"
    ></dxi-validation-rule>
  </dxi-item>

  <dxi-item
    *ngIf="createNewOptionGroup"
    dataField="optionGroup.sortId"
    [editorOptions]="{
      stylingMode: 'underlined',
      type: 'number'
    }"
  >
    <dxo-label text="{{ formatMessage('optionGroup.sortId') }}"></dxo-label>
    <dxi-validation-rule
      type="required"
      [message]="formatMessage('validation.required')"
    ></dxi-validation-rule>
    <dxi-validation-rule
      type="pattern"
      pattern="^[0-9]{1,3}$"
      [message]="formatMessage('validation.integerOnly')"
    ></dxi-validation-rule>
  </dxi-item>

  <dxi-item
    *ngIf="createNewOptionGroup"
    dataField="optionGroup.ancillaryType"
    [editorOptions]="{ stylingMode: 'underlined', maxLength: 3 }"
  >
    <dxo-label
      text="{{ formatMessage('optionGroup.ancillaryType') }}"
    ></dxo-label>
    <dxi-validation-rule
      type="required"
      [message]="formatMessage('validation.required')"
    ></dxi-validation-rule>
  </dxi-item>

  <dxi-item
    dataField="isNewOptionGroup"
    [editorOptions]="{
      onValueChanged: shouldCreateNewOptionGroup
    }"
    editorType="dxCheckBox"
  >
    <dxo-label
      text="{{ formatMessage('option.copy.newOptionGroup') }}"
    ></dxo-label>
  </dxi-item>
</dx-form>

<dx-toast
  [(visible)]="showToast"
  [type]="toastType"
  [message]="toastMessage"
></dx-toast>
