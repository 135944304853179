import { Component, OnInit, ViewChild } from "@angular/core";
import { formatMessage } from "devextreme/localization";
import config from "devextreme/core/config";
import repaintFloatingActionButton from "devextreme/ui/speed_dial_action/repaint_floating_action_button";
import { P3AuthService, ScreenService } from "../../shared/services";
import { DxDataGridComponent } from "devextreme-angular";
import { Router } from "@angular/router";
import {
  PreislistenKatalog,
  Produkt,
} from "../../shared/model/calculation/produkt";

import { OptionFormData } from "../../shared/model/calculation/optionFormData";
import { OptionGroupFormData } from "../../shared/model/calculation/optionGroupFormData";
import { compareStringsAlphabeticallyIgnoreCase } from "../../shared/utils/stringUtils";
import { Option } from "../../shared/model/calculation/option";
import { OptionGroup } from "../../shared/model/calculation/optionGroup";
import { OptionService } from "../../shared/services/option.service";
import {
  OptionPriceInformationRequestDe,
  OptionPriceInformationRequestUsCarton,
  OptionPriceInformationRequestUsDifference,
  OptionPriceInformationRequestUsInsert,
  OptionPriceInformationRequestUsOTP,
  OptionPriceInformationRequestUsSurchargeAndSubSurcharge,
  OptionRequest,
} from "../../shared/model/calculation/optionRequest";
import { ProduktService } from "../../shared/services/produkt.service";
import {
  OptionUnit,
  OptionUnitAdmin,
} from "../../shared/model/calculation/optionUnit";
import notify from "devextreme/ui/notify";
import { User } from "../../shared/model/user/user";
import { environment } from "src/environments/environment";
import {
  OptionCopyFormData,
  OptionCopyRequest,
} from "../../shared/model/calculation/OptionCopyFormData";

const enum OperationTypes {
  DELETE,
}

@Component({
  selector: "app-option-management",
  templateUrl: "./option-management.component.html",
  styleUrls: ["./option-management.component.scss"],
})
export class OptionManagementComponent implements OnInit {
  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;

  formatMessage = formatMessage;
  isMobile: boolean;

  showToast = false;
  toastMessage = "For some reason we need an initial value here...";
  toastType = "default";

  confirmationPopupTitle: string;
  confirmationPopupMessage: string;
  showConfirmationOptionPopup: boolean = false;
  showConfirmationOptionGroupPopup: boolean = false;
  confirmOperation: OperationTypes;
  confirmButtonText: string;

  produkte: Produkt[] = [];
  options: Option[] = [];
  optionUnits: OptionUnit[] = [];
  optionUnitsAdmin: OptionUnitAdmin[] = [];
  optionUnitPercent: OptionUnit;
  showNewOptionButton = false;
  optionPopupVisible = false;
  selectedOptionId: string | undefined = undefined;
  optionFormData: OptionFormData = new OptionFormData();

  preislistenKataloge: PreislistenKatalog[];
  selectedPreislistenKatalog: PreislistenKatalog;

  optionGroups: OptionGroup[];
  selectedOptionGroup: OptionGroup;
  selectedOptionGroupId: string | undefined = undefined;

  optionGroupFormData: OptionGroupFormData = new OptionGroupFormData();
  optionGroupPopupVisible = false;
  editOptionGroupDisabled = true;
  optionFormOptions: Option[];
  dependentOptions: Option[];
  optionFormDependentOptions: Option[];

  tmpLocale: string;
  environmentIsUsa = environment.isUsa;

  // related to Copy Option
  optionCopyFormVisible = false;
  optionCopyPopUpVisible = false;
  optionToCopy: Option;
  optionCopyFormData: OptionCopyFormData;

  user: User | undefined = {
    id: "",
    identity: {
      sub: "",
      loginName: "",
      firstName: "",
      lastName: "",
      email: "",
      isInternal: false,
      enabled: false,
      locale: "",
      userCreateDate: new Date(),
      userLastModifiedDate: new Date(),
    },
    newsletterStatus: { subscribed: "" },
    role: "",
    worksFor: [],
    notification: false,
  };

  constructor(
    private router: Router,
    private optionService: OptionService,
    private produktService: ProduktService,
    private screen: ScreenService,
    private authService: P3AuthService
  ) {
    this.translateLocale = this.translateLocale.bind(this);
  }

  ngOnInit(): void {
    this.isMobile =
      this.screen.sizes["screen-x-small"] || this.screen.sizes["screen-small"];

    config({
      floatingActionButtonConfig: {
        position: {
          my: "left bottom",
          at: "right top",
          of: "#user-grid",
          offset: "-30 25",
        },
      },
    });

    this.loadPreislistenKataloge();

    this.authService.getUser().subscribe(
      (e) => {
        if (e) {
          this.user = e;
        }
        this.tmpLocale = this.getLocale();
        this.loadOptionUnits();
      },
      (e) => {
        notify({
          message: e,
          type: "error",
          displayTime: 1500,
          height: 100,
        });
      }
    );
  }

  preislistenKatalogSelected(e) {
    this.editOptionGroupDisabled = true;
    this.selectedPreislistenKatalog = e.value;
    this.loadOptionGroups();
    this.options = [];
    this.loadProdukte();
    this.loadDependentOptions();
  }

  optionGroupSelected(e) {
    this.selectedOptionGroup = e.value;
    this.selectedOptionGroupId = this.selectedOptionGroup.id;
    this.showNewOptionButton = true;
    this.editOptionGroupDisabled = false;
    this.loadOptions();
  }

  loadOptionUnits(): void {
    this.optionUnits = [];
    try {
      this.optionService
        .findAllOptionUnit()
        .toPromise()
        .then((result) => (this.optionUnits = [...result]))
        .finally(() => {
          this.optionUnitsAdmin = [];
          this.optionUnits.forEach((optionUnit) => {
            if (optionUnit.unitNameDe !== "%") {
              //Die Einheit '%' darf nicht vom User ausgewählt werden.
              var optionUnitAdmin = new OptionUnitAdmin();
              optionUnitAdmin.id = optionUnit.id;

              if (this.tmpLocale == "de-DE") {
                optionUnitAdmin.unitName = optionUnit.unitNameDe;
              } else {
                optionUnitAdmin.unitName = optionUnit.unitNameEn;
              }

              this.optionUnitsAdmin.push(optionUnitAdmin);
            } else {
              this.optionUnitPercent = optionUnit;
            }
          });
          this.sortOptionUnits();
        })
        .catch(() => {
          this.toastType = "error";
          this.toastMessage = formatMessage("optionunit.load.error");
          this.showToast = true;
        });
    } catch (e) {}
  }

  loadProdukte(): void {
    if (this.selectedPreislistenKatalog) {
      this.produktService
        .findProduktsByPreislistenKatalog(this.selectedPreislistenKatalog)
        .toPromise()
        .then((result) => (this.produkte = [...result]))
        .catch(() => {
          this.toastType = "error";
          this.toastMessage = formatMessage("produkt.load.error");
          this.showToast = true;
        });
    }
  }

  loadDependentOptions() {
    if (this.selectedPreislistenKatalog) {
      this.optionService
        .getOptionsByPreislistenKatalog(this.selectedPreislistenKatalog)
        .subscribe(
          (data) => {
            this.dependentOptions = [...data];
            console.log(this.dependentOptions);
            this.mapOptionTypeDisplayName(this.dependentOptions);
          },
          () => {
            this.toastType = "error";
            this.toastMessage = formatMessage("dependentOption.load.error");
            this.showToast = true;
          }
        );
    }
  }

  placeFloatingActionButton(): void {
    // The floating action button has a known bug, that the initial position is wrong on a dx-data-grid. By invoking
    // this method on the "onContentReady" event of the dx-data-grid, the button is on the correct position.
    // https://supportcenter.devexpress.com/ticket/details/t743517/floating-action-button-the-initial-position-is-incorrect-if-the-container-doesn-t-have
    this.showNewOptionButton = true;
    repaintFloatingActionButton();
  }

  mapOptionTypeDisplayName(options: Option[]) {
    for (let i = 0; i < options.length; i++) {
      switch (options[i].optionType) {
        case "CARTONS":
          options[i].optionTypeDisplayName = "Cartons (Qty/Unit: 1000)";
          break;
        case "DIFFERENCE":
          options[i].optionTypeDisplayName = "Difference (Qty/Unit: 1000)";
          break;
        case "INSERTS":
          options[i].optionTypeDisplayName = "Inserts (Qty/Unit: 1000)";
          break;
        case "ONE_TIME_PRICES":
          options[i].optionTypeDisplayName = "One-time Prices (Qty/Unit: 1)";
          break;
        case "ONE_TIME_PRICES_1000":
          options[i].optionTypeDisplayName = "One-time Prices (Qty/Unit: 1000)";
          break;
        case "SURCHARGE":
          options[i].optionTypeDisplayName = "Surcharge (Qty/Unit: 1)";
          break;
        case "SUB_SURCHARGE":
          options[i].optionTypeDisplayName = "Sub-Surcharge (Qty/Unit: 1)";
          break;
        default:
          break;
      }
    }
  }

  loadOptions(): void {
    if (this.selectedOptionGroup) {
      this.optionService
        .findOptionsByOptionGroup(this.selectedOptionGroup)
        .toPromise()
        .then((result) => {
          this.options = [...result];
          this.mapOptionTypeDisplayName(this.options);
        })
        .catch(() => {
          this.toastType = "error";
          this.toastMessage = formatMessage("option.load.error");
          this.showToast = true;
        });
    }
  }

  loadOptionGroups(): void {
    if (this.selectedPreislistenKatalog) {
      this.optionService
        .findOptionGroupsByPreislistenKatalog(this.selectedPreislistenKatalog)
        .toPromise()
        .then((result) => {
          this.optionGroups = [...result];

          if (this.optionGroups.length > 1) {
            this.optionService.sortOptionGroups(this.optionGroups);
          }
        })
        .catch(() => {
          this.toastType = "error";
          this.toastMessage = formatMessage("optiongroup.load.error");
          this.showToast = true;
        });
    }
  }

  sortOptionUnits(): void {
    this.optionUnitsAdmin.sort((optionUnit1, optionUnit2) =>
      this.compareOptionUnitsByName(optionUnit1, optionUnit2)
    );
  }

  async loadPreislistenKataloge(): Promise<void> {
    try {
      this.preislistenKataloge = await this.produktService
        .findAllPreislistenKataloge()
        .toPromise();

      this.sortPreislistenKataloge();
    } catch (e) {}
  }

  sortPreislistenKataloge(): void {
    this.preislistenKataloge.sort((preislistenKatalog1, preislistenKatalog2) =>
      this.comparePreislistenKatalogeByName(
        preislistenKatalog1,
        preislistenKatalog2
      )
    );
  }

  comparePreislistenKatalogeByName(
    preislistenKatalog1: PreislistenKatalog,
    preislistenKatalog2: PreislistenKatalog
  ): number {
    return compareStringsAlphabeticallyIgnoreCase(
      preislistenKatalog1.name,
      preislistenKatalog2.name
    );
  }

  compareOptionUnitsByName(
    optionUnit1: OptionUnitAdmin,
    optionUnit2: OptionUnitAdmin
  ): number {
    return compareStringsAlphabeticallyIgnoreCase(
      optionUnit1.unitName,
      optionUnit2.unitName
    );
  }

  createOption(): void {
    if (!this.selectedOptionGroup) {
      this.toastMessage = formatMessage(
        `option.preislistenkatalog.notselected`
      );
      this.toastType = "error";
      this.showToast = true;
    } else {
      this.selectedOptionId = undefined;
      this.optionFormData = new OptionFormData();
      this.optionFormOptions = this.options;
      this.optionFormData.optionGroup = this.selectedOptionGroup;
      this.optionFormData.preislistenKatalog = this.selectedPreislistenKatalog;
      this.optionFormData.optionPriceInformation = [];
      this.optionFormDependentOptions = this.dependentOptions;
      this.optionPopupVisible = true;
    }
  }

  editOption(option: Option): void {
    this.selectedOptionId = option.id;
    this.optionFormOptions = this.options;
    this.optionFormData = new OptionFormData(option);

    this.produktService
      .findProduktsByPreislistenKatalog(this.selectedPreislistenKatalog)
      .toPromise()
      .then((result) => {
        [...result].forEach((produkt) => {
          if (option.associatedProducts) {
            option.associatedProducts.forEach((associatedProduct) => {
              if (produkt.id === associatedProduct) {
                this.optionFormData.associatedProducts.push(produkt);
              }
            });
          }
        });
      })
      .catch(() => {
        this.toastType = "error";
        this.toastMessage = formatMessage("produkt.load.error");
        this.showToast = true;
      });

    this.optionFormDependentOptions = this.dependentOptions?.filter(
      (value) => option.id != value.id
    );

    this.optionFormData.dependentOptions =
      this.optionFormDependentOptions?.filter((value) => {
        return option.dependentOptions?.includes(value.id);
      });

    if (option.optionUnit && option.optionUnit.id) {
      this.optionFormData.optionUnitId = option.optionUnit.id;
    }

    this.optionFormData.preislistenKatalog = this.selectedPreislistenKatalog;
    this.optionPopupVisible = true;
  }

  async saveOption(data: OptionFormData): Promise<void> {
    if (data.optionType.startsWith("AUFSCHLAG")) {
      data.optionUnitId = this.optionUnitPercent.id;
    }
    if (!this.selectedOptionId) {
      this.environmentIsUsa
        ? await this.saveNewOptionAndUpdateViewUs(data)
        : await this.saveNewOptionAndUpdateViewDe(data);
    } else {
      this.environmentIsUsa
        ? await this.updateOptionAndUpdateViewUs(data)
        : await this.updateOptionAndUpdateViewDe(data);
    }
  }

  async saveOptionGroup(data: OptionGroupFormData): Promise<void> {
    if (!this.selectedOptionGroupId) {
      await this.saveNewOptionGroupAndUpdateView(data);
    } else {
      await this.updateOptionGroupAndUpdateView(data);
    }
  }

  removeOptionGroupFromView() {
    const selectedOptionGroupIndex = this.optionGroups.findIndex(
      (it) => it.id === this.selectedOptionGroupId
    );

    if (selectedOptionGroupIndex !== -1) {
      this.optionGroups.splice(selectedOptionGroupIndex, 1);
    }
  }

  removeOptionFromView() {
    const selectedOptionIndex = this.options.findIndex(
      (it) => it.id === this.selectedOptionId
    );

    if (selectedOptionIndex !== -1) {
      this.options.splice(selectedOptionIndex, 1);
    }
  }

  showConfirmationPopupForDeleteOptionGroup() {
    if (this.options.length > 0) {
      this.toastType = "error";
      this.toastMessage = formatMessage(
        "optiongroup.delete.error.existingoptions"
      );
      this.showToast = true;
    } else {
      this.confirmationPopupTitle = formatMessage(
        `optiongroup.delete.confirm.title`
      );
      this.confirmationPopupMessage = formatMessage(
        `optiongroup.delete.confirm.message`,
        this.selectedOptionGroup.groupName
      );
      this.confirmButtonText = formatMessage("delete");

      this.confirmOperation = OperationTypes.DELETE;
      this.showConfirmationOptionGroupPopup = true;
    }
  }

  deleteOptionGroup(): void {
    if (!this.selectedOptionGroupId) {
      return;
    }

    this.optionService
      .deleteOptionGroup(this.selectedOptionGroupId)
      .toPromise()
      .then(() => {
        this.removeOptionGroupFromView();
        this.toastType = "success";
        this.toastMessage = formatMessage("optiongroup.delete.success");
      })
      .catch(() => {
        this.toastType = "error";
        this.toastMessage = formatMessage("optiongroup.delete.error");
      })
      .finally(() => {
        this.showToast = true;
      });
  }

  showConfirmationPopupForDeleteOption(option: Option) {
    this.selectedOptionId = option.id;

    this.confirmationPopupTitle = formatMessage(`option.delete.confirm.title`);
    this.confirmationPopupMessage = formatMessage(
      `option.delete.confirm.message`,
      option.optionName
    );
    this.confirmButtonText = formatMessage("delete");

    this.confirmOperation = OperationTypes.DELETE;
    this.showConfirmationOptionPopup = true;
  }

  deleteOptionAndUpdateView(): void {
    if (!this.selectedOptionId) return;
    this.optionService
      .deleteOption(this.selectedOptionId)
      .toPromise()
      .then(() => {
        this.removeOptionFromView();
        if (this.environmentIsUsa) this.removeOptionFromDependentOptionList();
        this.toastType = "success";
        this.toastMessage = formatMessage("option.delete.success");
      })
      .catch(() => {
        this.toastType = "error";
        this.toastMessage = formatMessage("option.delete.error");
      })
      .finally(() => {
        this.showToast = true;
      });
  }

  // Creating NewOptionRequest for De and saing new Option
  async saveNewOptionAndUpdateViewDe(data: OptionFormData): Promise<void> {
    let optionPriceRequests: OptionPriceInformationRequestDe[] = [];
    data.optionPriceInformation.forEach((optionPrice) => {
      optionPriceRequests.push(
        new OptionPriceInformationRequestDe(
          optionPrice.von,
          optionPrice.bis,
          optionPrice.fixKosten,
          optionPrice.variableKosten,
          optionPrice.fortDruckKosten
        )
      );
    });
    await this.saveNewOption(data, optionPriceRequests);
  }

  async saveNewOptionAndUpdateViewUs(data: OptionFormData): Promise<void> {
    let optionPriceRequests = this.createOptionPriceInformationRequest(data);
    await this.saveNewOption(data, optionPriceRequests);
  }

  // For saving Option
  async saveNewOption(
    data: OptionFormData,
    optionPriceRequests:
      | OptionPriceInformationRequestDe[]
      | OptionPriceInformationRequestUsInsert[]
      | OptionPriceInformationRequestUsCarton[]
      | OptionPriceInformationRequestUsDifference[]
      | OptionPriceInformationRequestUsOTP[]
      | OptionPriceInformationRequestUsSurchargeAndSubSurcharge[]
  ) {
    await this.optionService
      .createOption(
        new OptionRequest(
          data.optionName,
          data.optionType,
          data.optionPosition,
          data.sortId,
          data.fixedValue,
          data.optionGroup.id,
          data.preislistenKatalog.id,
          <string>data.optionUnitId,
          optionPriceRequests,
          data.associatedProducts?.map((p) => p.id),
          data.invoiceCategory,
          data.glCode,
          data.requiredQuantityType,
          data.quantityType,
          data.optionPriceInformationColumnNames,
          data.ancillaryCode,
          data.dependentOptions?.map((o) => o.id),
          data.hideInFrontend,
          data.covers
        )
      )
      .toPromise()
      .then((createdOptionResponse) => {
        this.mapOptionTypeDisplayName([createdOptionResponse]);
        this.options.unshift(createdOptionResponse);
        this.dependentOptions.push(createdOptionResponse);
        this.toastMessage = formatMessage("option.create.success");
        this.toastType = "success";
      })
      .catch((e) => {
        this.toastMessage = formatMessage("option.create.error");
        this.toastType = "error";
      })
      .finally(() => (this.showToast = true));
  }

  async updateOptionAndUpdateViewDe(data: OptionFormData): Promise<void> {
    let optionPriceRequests: OptionPriceInformationRequestDe[] = [];
    data.optionPriceInformation.forEach((optionPrice) => {
      optionPriceRequests.push(
        new OptionPriceInformationRequestDe(
          optionPrice.von,
          optionPrice.bis,
          optionPrice.fixKosten,
          optionPrice.variableKosten,
          optionPrice.fortDruckKosten
        )
      );
    });
    await this.updateOption(data, optionPriceRequests);
  }

  async updateOptionAndUpdateViewUs(data: OptionFormData): Promise<void> {
    let optionPriceRequests:
      | OptionPriceInformationRequestUsOTP[]
      | OptionPriceInformationRequestUsInsert[]
      | OptionPriceInformationRequestUsCarton[]
      | OptionPriceInformationRequestUsDifference[]
      | OptionPriceInformationRequestUsSurchargeAndSubSurcharge[] =
      this.createOptionPriceInformationRequest(data);
    await this.updateOption(data, optionPriceRequests);
  }

  async saveNewOptionGroupAndUpdateView(
    data: OptionGroupFormData
  ): Promise<void> {
    await this.optionService
      .createOptionGroup(
        data.groupName,
        data.preislistenKatalog.id,
        data.sortId,
        data.ancillaryType
      )
      .toPromise()
      .then((createdOptionGroup) => {
        this.optionGroups.unshift(createdOptionGroup);
        this.optionService.sortOptionGroups(this.optionGroups);
        this.selectedOptionGroup = createdOptionGroup;
        this.selectedOptionGroupId = this.selectedOptionGroup.id;
        this.toastMessage = formatMessage("optionGroup.create.success");
        this.toastType = "success";
      })
      .catch((e) => {
        this.toastMessage = formatMessage("optionGroup.create.error");
        this.toastType = "error";
      })
      .finally(() => (this.showToast = true));
  }

  async updateOptionGroupAndUpdateView(
    data: OptionGroupFormData
  ): Promise<void> {
    this.updateOptionGroup(data)
      .then((updatedOptionGroup) => {
        this.updateOptionGroupInView(updatedOptionGroup);
        this.optionService.sortOptionGroups(this.optionGroups);
        this.toastMessage = formatMessage("optionGroup.update.success");
        this.toastType = "success";
      })
      .catch((e) => {
        if (["ALREADY_EXISTS", "NOT_FOUND"].includes(e.message)) {
          this.toastMessage = formatMessage(`optionGroup.error.${e.message}`);
        } else {
          this.toastMessage = formatMessage("optionGroup.update.error");
        }

        this.toastType = "error";
      })
      .finally(() => (this.showToast = true));
  }

  async updateOptionGroup(data: OptionGroupFormData): Promise<OptionGroup> {
    return await this.optionService
      .updateOptionGroup(
        data.id,
        data.groupName,
        data.sortId,
        data.ancillaryType
      )
      .toPromise();
  }

  updateOptionGroupInView(updatedOptionGroup: OptionGroup) {
    const selectedOptionGroupIndex = this.optionGroups.findIndex(
      (it) => it.id === this.selectedOptionGroupId
    );

    if (selectedOptionGroupIndex !== -1) {
      this.optionGroups.splice(selectedOptionGroupIndex, 1, updatedOptionGroup);
    }

    this.selectedOptionGroup = updatedOptionGroup;
  }

  translateLocale(rowData): string {
    return formatMessage(`locale.${rowData.identity.locale}`);
  }

  createOptionGroup(): void {
    this.selectedOptionGroupId = undefined;
    this.optionGroupFormData = new OptionGroupFormData();
    this.optionGroupFormData.preislistenKatalog =
      this.selectedPreislistenKatalog;
    this.optionGroupPopupVisible = true;
  }

  editOptionGroup(): void {
    this.selectedOptionGroupId = this.selectedOptionGroup.id;
    this.optionGroupFormData = new OptionGroupFormData();
    this.optionGroupFormData.id = this.selectedOptionGroup.id;
    this.optionGroupFormData.groupName = this.selectedOptionGroup.groupName;
    this.optionGroupFormData.sortId = this.selectedOptionGroup.sortId;
    this.optionGroupFormData.ancillaryType =
      this.selectedOptionGroup.ancillaryType;
    this.optionGroupPopupVisible = true;
  }

  getLocale() {
    const locale = this.user?.identity.locale;
    return locale != null ? locale : navigator.language;
  }

  createOptionPriceInformationRequest(data: OptionFormData): any[] {
    let optionPriceRequests: any[] = [];
    switch (data.optionType) {
      case "CARTONS":
        {
          data.optionPriceInformation.forEach((optionPrice) => {
            optionPriceRequests.push(
              new OptionPriceInformationRequestUsCarton(
                optionPrice.from,
                optionPrice.to,
                optionPrice.col0,
                optionPrice.col1,
                optionPrice.col2,
                optionPrice.col3,
                optionPrice.col4,
                optionPrice.col5,
                optionPrice.col6,
                optionPrice.col7,
                optionPrice.col8,
                optionPrice.col9
              )
            );
          });
        }
        break;
      case "DIFFERENCE":
        {
          data.optionPriceInformation.forEach((optionPrice) => {
            optionPriceRequests.push(
              new OptionPriceInformationRequestUsDifference(
                optionPrice.from,
                optionPrice.to,
                optionPrice.col0
              )
            );
          });
        }
        break;
      case "INSERTS":
        {
          data.optionPriceInformation.forEach((optionPrice) => {
            optionPriceRequests.push(
              new OptionPriceInformationRequestUsInsert(
                optionPrice.from,
                optionPrice.to,
                optionPrice.insertPages,
                optionPrice.insertConfig,
                optionPrice.makeReady,
                optionPrice.plates,
                optionPrice.run
              )
            );
          });
        }
        break;
      case "ONE_TIME_PRICES":
      case "ONE_TIME_PRICES_1000":
        {
          data.optionPriceInformation.forEach((optionPrice) => {
            optionPriceRequests.push(
              new OptionPriceInformationRequestUsOTP(optionPrice.price)
            );
          });
        }
        break;
      case "SURCHARGE":
      case "SUB_SURCHARGE":
        {
          data.optionPriceInformation.forEach((optionPrice) => {
            optionPriceRequests.push(
              new OptionPriceInformationRequestUsSurchargeAndSubSurcharge(
                optionPrice.surcharge
              )
            );
          });
        }
        break;

      default:
        break;
    }
    return optionPriceRequests;
  }

  async updateOption(data: OptionFormData, optionPriceRequests: any[]) {
    var optionRequest = new OptionRequest(
      data.optionName,
      data.optionType,
      data.optionPosition,
      data.sortId,
      data.fixedValue,
      data.optionGroup.id,
      data.preislistenKatalog.id,
      <string>data.optionUnitId,
      optionPriceRequests,
      data.associatedProducts?.map((p) => p.id),
      data.invoiceCategory,
      data.glCode,
      data.requiredQuantityType,
      data.quantityType,
      data.optionPriceInformationColumnNames,
      data.ancillaryCode,
      data.dependentOptions?.map((o) => o.id),
      data.hideInFrontend,
      data.covers
    );

    optionRequest.id = data.id;

    await this.optionService
      .updateOption(optionRequest)
      .toPromise()
      .then((createdOptionResponse) => {
        const selectedOptionIndex = this.options.findIndex(
          (it) => it.id === this.selectedOptionId
        );
        this.mapOptionTypeDisplayName([createdOptionResponse]);
        if (selectedOptionIndex !== -1) {
          this.options.splice(selectedOptionIndex, 1, createdOptionResponse);
        }

        this.toastMessage = formatMessage("option.update.success");
        this.toastType = "success";
      })
      .catch((e) => {
        this.toastMessage = formatMessage("option.update.error");
        this.toastType = "error";
      })
      .finally(() => (this.showToast = true));
  }

  private removeOptionFromDependentOptionList() {
    const selectedOptionIndex = this.dependentOptions.findIndex(
      (it) => it.id === this.selectedOptionId
    );
    if (selectedOptionIndex !== -1) {
      this.dependentOptions.splice(selectedOptionIndex, 1);
    }
  }

  showOptionCopyForm(option: Option): void {
    // cause option.preislistenKatalog == null
    option.preislistenKatalog = this.selectedPreislistenKatalog;
    option.optionGroup = this.selectedOptionGroup;

    this.optionCopyFormData = new OptionCopyFormData(option);
    this.optionCopyFormVisible = true;
  }

  copyOption(optionCopyFormData: OptionCopyFormData) {
    const optionRequest = new OptionCopyRequest(optionCopyFormData);
    this.optionService
      .createOptionCopy(optionRequest)
      .toPromise()
      .then((createdOptionResponse) => {
        // Only if copied Option is in same OptionGroup
        if (
          createdOptionResponse.optionGroup.id == this.selectedOptionGroup.id
        ) {
          this.mapOptionTypeDisplayName([createdOptionResponse]);
          this.options.unshift(createdOptionResponse);
          this.dependentOptions.push(createdOptionResponse);
          this.optionGroups.unshift(new OptionGroup());
        }
        this.toastMessage = formatMessage("option.copy.success");
        this.toastType = "success";

        // when new OptionGroup in same PriceList is created load Optiongroups
        if (
          optionRequest.isNewOptionGroup &&
          optionRequest.preislistenKatalogId ==
            this.selectedPreislistenKatalog.id
        ) {
          this.optionGroups.unshift(createdOptionResponse.optionGroup);
        }

        setTimeout(() => (this.optionCopyPopUpVisible = true), 900);
      })
      .catch((e) => {
        this.toastMessage = formatMessage("option.copy.error");
        this.toastType = "error";
      })
      .finally(() => {
        this.showToast = true;
        this.optionCopyFormVisible = false;
        // Timeout for smooth transition
      });
  }
}
