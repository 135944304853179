import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
} from "@angular/core";
import { ActionPopupModule } from "../../action-popup/action-popup.component";
import { formatMessage } from "devextreme/localization";
import { AlertModule } from "../../alert/alert.component";

@Component({
  selector: "app-option-copy-pop-up",
  templateUrl: "./option-copy-pop-up.component.html",
  styleUrls: ["./option-copy-pop-up.component.scss"],
})
export class OptionCopyPopUpComponent implements OnInit {
  formatMessage = formatMessage;
  @Input() visible: boolean = false;
  @Output() visibleChange = new EventEmitter<boolean>();

  title: string = formatMessage("confirm.optionCopy.title");
  textMessage: string = formatMessage("confirm.optionCopy.text");
  buttonText: string = formatMessage("confirm");

  constructor() {}

  ngOnInit(): void {}
}

@NgModule({
  imports: [ActionPopupModule, AlertModule],
  exports: [OptionCopyPopUpComponent],
  declarations: [OptionCopyPopUpComponent],
})
export class OptionCopyPopUpModule {}
