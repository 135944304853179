<dx-form
  id="optionGroupForm"
  labelMode="floating"
  [formData]="optionGroupData"
  colCount="2"
  [colCountByScreen]="{ xs: 1 }"
  validationGroup="newOptionGroupFormValidation"
>
  <dxi-item
    dataField="groupName"
    [label]="{ text: formatMessage('optionGroup.name') }"
    [editorOptions]="{ stylingMode: 'underlined' }"
  >
    <dxi-validation-rule
      type="required"
      [message]="formatMessage('validation.required')"
    ></dxi-validation-rule>
  </dxi-item>
  <dxi-item
    dataField="sortId"
    [label]="{ text: formatMessage('optionGroup.sortId') }"
    [editorOptions]="{ stylingMode: 'underlined' }"
  >
    <dxi-validation-rule
      type="required"
      [message]="formatMessage('validation.required')"
    ></dxi-validation-rule>
    <dxi-validation-rule
      type="pattern"
      pattern="^[0-9]{1,3}$"
      [message]="formatMessage('validation.integerOnly')"
    ></dxi-validation-rule>
  </dxi-item>
  <dxi-item
    *ngIf="environmentIsUsa"
    dataField="ancillaryType"
    [label]="{ text: formatMessage('optionGroup.ancillaryType') }"
    [editorOptions]="{ stylingMode: 'underlined', maxLength: 3 }"
  >
  </dxi-item>
</dx-form>
