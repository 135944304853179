// This Form is used for copying Options
// only required Fields are used

import { PreislistenKatalog } from "./produkt";
import { SimpleOptionGroup } from "./optionGroup";
import { Option } from "./option";
import { OptionGroupFormData } from "./optionGroupFormData";

export class OptionCopyFormData {
  optionToCopyId: string;
  preislistenKatalog: PreislistenKatalog;

  // The OptionGroup can be an existing
  // or a new OptionGroup is going to be created
  //
  optionGroup: OptionGroupFormData;
  isNewOptionGroup: boolean;

  constructor(option: Option) {
    this.optionToCopyId = option.id!;
    this.preislistenKatalog = option.preislistenKatalog!;
    this.optionGroup = option.optionGroup!;
  }
}

export class OptionCopyRequest {
  optionToCopyId: string;
  preislistenKatalogId: string;
  optionGroup: SimpleOptionGroup;
  isNewOptionGroup: boolean;

  constructor(form: OptionCopyFormData) {
    this.optionToCopyId = form.optionToCopyId!;
    this.preislistenKatalogId = form.preislistenKatalog.id!;
    this.optionGroup = {
      preislistenKatalogId: form.preislistenKatalog.id!,
      id: form.optionGroup?.id,
      groupName: form.optionGroup.groupName!,
      sortId: form.optionGroup.sortId!,
      ancillaryType: form.optionGroup.ancillaryType!,
    };

    // if isNewOptionGroup undefined --> false
    this.isNewOptionGroup = form.isNewOptionGroup ? true : false;
  }
}
